<template>
  <div class="Frequently">
   
    <div class="mainbody">
     <Listleft :Listleft="Listleft" :Listright="Listright" />  
    </div>

  </div>
</template>
<script>
import Listleft from './Listltabs.vue'
export default {
  components: {

    Listleft
  },
  data() {
    return {
      Listleft: [
        "购物常见问题",
        "优币使用常见问题",
        "发票常见问题",
        "邀请好友帮助说明",
        "优惠券使用说明",
        "礼品卡使用说明",
        "商品评价说明",
        "中再物贸云网会员介绍",
        "成长值介绍",
      ],
      Listright:[
        "购物常见问题",
        "优币使用常见问题",
        "发票常见问题",
        "邀请好友帮助说明",
        "优惠券使用说明",
        "礼品卡使用说明",
        "商品评价说明",
        "中再物贸云网会员介绍",
        "成长值介绍"
      ]

    };
  },
  methods: {
  
  },
};
</script>
<style lang="less" scoped>
.Frequently {
  user-select: none;
  .mainbody {
    width: 1440px;
    height: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
</style>